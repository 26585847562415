import { Reducer } from 'redux';
import ActionTypes from '../../constants/action-types';
import { AuthState } from '../../model/state';
import BaseDefault from '../../constants/state';

const initialState: AuthState = { ...BaseDefault, isLoggedIn: false };

const reducer: Reducer<any> = (state: AuthState = initialState, action): AuthState => {
	switch (action.type) {
		case ActionTypes.auth.SIGN_IN_WITH_EMAIL_REQUEST:
		case ActionTypes.auth.SIGN_UP_REQUEST: {
			return {
				...state,
				requesting: true,
			}
		}
		case ActionTypes.auth.SIGN_IN_WITH_EMAIL_FAILURE: {
			return {
				...state,
				requesting: false,
				error: action.payload,
				data: undefined,
				isLoggedIn: false,
			}
		}
		case ActionTypes.auth.SIGN_UP_FAILURE: {
			return {
				...state,
				requesting: false,
				error: action.payload,
			}
		}
		case ActionTypes.auth.SIGN_IN_WITH_EMAIL_SUCCESS: {
			return {
				...state,
				requesting: false,
				data: action.payload.data,
				error: '',
				isLoggedIn: true,
			}
		}
		case ActionTypes.auth.SIGN_UP_SUCCESS: {
			return {
				...state,
				requesting: false,
				data: action.payload,
			}
		}
		default: {
			return state;
		}
	}
}

export default reducer;
