import * as React from 'react';
import {
	createStackNavigator,
	StackNavigationOptions,
} from '@react-navigation/stack';
import { StyleSheet } from 'react-native';
import { User } from '../model/auth';

const Stack = createStackNavigator();

const styles = StyleSheet.create({
	headerSideWrapper: {
		width: 60,
		alignItems: 'center',
	},
});

interface MainProps {
	navigations: any;
	user: User;
	isLoggedIn: boolean;
	option?: StackNavigationOptions;
}

const StackNavigation = ({ navigations, user, isLoggedIn, option }: MainProps) => {
	return (
		<Stack.Navigator
			screenOptions={() => ({
				...option,
			})}
			initialRouteName={navigations[0].name}
		>
			{navigations.map((r: any) => {
				return (
					<Stack.Screen
						key={r.name}
						name={r.name}
						component={r.component}
						options={({navigation}) => ({
							title: r.name,
							...r.headerOptions(navigation, user, isLoggedIn),
						})}
					/>
				);
			})}
		</Stack.Navigator>
	);
};

export default StackNavigation;
