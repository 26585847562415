import React from 'react';
import { StyleSheet, TouchableOpacity, View, Dimensions, ScrollView } from 'react-native';
import { Text} from '@ui-kitten/components';
import { SafeAreaLayout } from '../../components/ui-kitten-kit/safe-area-layout.component';
import Colors from '../../constants/color';

const screenWidth = Dimensions.get('window').width;

export const ProfileScreen = (props): React.ReactElement => {

	const { navigation } = props;


	return (
		<SafeAreaLayout
      style={styles.safeArea}
      insets='top'>
				<ScrollView>
					<View style={styles.headerContainer}>
						<TouchableOpacity style={styles.leftColumn} onPress={() => navigation.navigate('home')}>
							<Text style={styles.permalinkText}>
								Halaman utama
							</Text>
						</TouchableOpacity>
						<View style={styles.rightColumn} />
					</View>
				</ScrollView>
    </SafeAreaLayout>
	);
};

const styles = StyleSheet.create({
  safeArea: {
		flex: 1,
		backgroundColor: Colors.Grey2
	},
	headerContainer: {
		paddingVertical: 12,
		paddingHorizontal: 16,
		flexDirection: 'row',
		height: 45
	},
	leftColumn: {
		flex: 5,
	},
	rightColumn: {
		flex: 5,
		textAlign: 'right'
	},
	permalinkText: {
		color: Colors.BlueOne
	},
});
