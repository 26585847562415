import * as React from 'react';
import { StyleSheet, View, Image, TouchableOpacity, Text } from 'react-native';
import { StackNavigationOptions } from '@react-navigation/stack';
import Colors from '../../constants/color';
import { headerLogo } from '../../constants/images';
import { User } from 'src/model/auth';

const styles = StyleSheet.create({
	header: {
		backgroundColor: Colors.Theme,
		borderBottomWidth: 0,
		height: 60
	},
	headerSideWrapper: {
		alignItems: 'center',
		flexDirection: 'row',
	},
	headerLogo: {
		width: 45,
		height: 45,
		marginLeft: 22,
		marginRight: 11,
		resizeMode: 'contain'
	},
	headerTitle: {
		color: Colors.White,
		fontFamily: 'Montserrat_700Bold',
	},
	text: {
		color: Colors.White,
		marginRight: 22,
		marginLeft: 11,
		fontWeight: '600'
	}
});

export const headerLeft = () => (
	<View style={styles.headerSideWrapper}>
		<Image source={headerLogo}  style={styles.headerLogo} />
	</View>
);

const LogoHeader = (navigation: any, user: User | undefined, isLoggedIn: boolean) => {
	const stackNavigationoptions: StackNavigationOptions = {
		headerLeft,
		headerRight: () => (
			<TouchableOpacity
			style={styles.headerSideWrapper}
			onPress={() => isLoggedIn ? navigation.navigate('profile') : navigation.navigate('auth')}
		>
			<Text style={styles.text}>
				{isLoggedIn ? (user?.fullName.length || 0) > 0 ? user?.fullName : 'Anonim' : 'Login'}
			</Text>
		</TouchableOpacity>
		),
		headerTitle: () => null,
		headerStyle: styles.header,
	};
	return stackNavigationoptions;
}
export default LogoHeader;
