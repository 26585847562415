import { Reducer } from 'redux';
import ActionTypes from '../../constants/action-types';
import { OrderState, OrderStateDefault } from '../../model/state';

const initialState: OrderState = { ...OrderStateDefault };

const reducer: Reducer<any> = (state: OrderState = initialState, action): OrderState => {
	switch (action.type) {
		case ActionTypes.order.PAYMENT_METHODS_REQUEST: {
			return {
				...state,
				paymentMethods: {
					...state.paymentMethods,
					requesting: true,
					error: ''
				}
			}
		}
		case ActionTypes.order.PAYMENT_METHODS_FAILURE: {
			return {
				...state,
				paymentMethods: {
					...state.paymentMethods,
					requesting: false,
					error: action.payload,
					list: []
				}
			}
		}
		case ActionTypes.order.PAYMENT_METHODS_SUCCESS: {
			return {
				...state,
				paymentMethods: {
					...state.paymentMethods,
					requesting: false,
					list: action.payload
				}
			}
		}
		case ActionTypes.order.CLEAR_PAYMENT_METHOD_SUCCESS: {
			return {
				...state,
				paymentMethods: {
					...state.paymentMethods,
					list: []
				}
			}
		}
		case ActionTypes.order.CHECKOUT_REQUEST: {
			return {
				...state,
				checkout: {
					...state.checkout,
					requesting: true,
					error: ''
				}
			}
		}
		case ActionTypes.order.CHECKOUT_REQUEST: {
			return {
				...state,
				checkout: {
					...state.checkout,
					requesting: false,
					error: action.payload
				}
			}
		}
		case ActionTypes.order.CHECKOUT_SUCCESS: {
			return {
				...state,
				checkout: {
					...state.checkout,
					requesting: false,
					data: action.payload
				}
			}
		}
		default: {
			return state;
		}
	}
}

export default reducer;
