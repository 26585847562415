import React from 'react';
import { Dimensions, StyleSheet, Image, TouchableOpacity, ListRenderItemInfo } from 'react-native';
import { List, Text, ListProps, ListElement, CardElement } from '@ui-kitten/components';

const screenWidth = Dimensions.get('window').width;

interface Data {
	image: string;
	name: string;
}

export interface LayoutGridListProps extends Omit<ListProps, 'renderItem'> {
	data: any;
	callback: (data: any) => void;
}

export type LayoutGridListElement = React.ReactElement<LayoutGridListProps>;

export const LayoutGridList = (props: LayoutGridListProps): ListElement => {

	const { contentContainerStyle, callback, ...listProps } = props;

	const renderItemHeader = (info: ListRenderItemInfo<Data>): React.ReactElement => {
		return (
		<Image
			style={styles.itemHeader}
			source={{ uri: info.item.image }}
		/>
	); };

	const renderItem = (info: ListRenderItemInfo<Data>): CardElement => (
		<TouchableOpacity style={styles.productItem} onPress={() => callback(info.item)}>
			{renderItemHeader(info)}
			<Text style={styles.itemTitle}>
				{info.item.name}
			</Text>
		</TouchableOpacity>
	);

	return (
		<List
			{...listProps}
			contentContainerStyle={[styles.productList, contentContainerStyle]}
			numColumns={3}
			renderItem={renderItem}
		/>
	);
};

const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: 'background-basic-color-2',
	},
	productList: {
		paddingHorizontal: 0,
		paddingVertical: 0,
	},
	productItem: {
		flex: 1,
		marginHorizontal: 4,
		marginVertical: 12,
		maxWidth: (screenWidth - 24) / 3 - 8,
		backgroundColor: 'background-basic-color-1',
	},
	itemHeader: {
		height: 104,
		resizeMode: 'cover',
	},
	itemTitle: {
		fontSize: 14,
		lineHeight: 21,
		fontWeight: '600',
		marginVertical: 7,
		textAlign: 'center',
		justifyContent: 'center',
	},
	iconButton: {
		paddingHorizontal: 0,
	},
});
