import { AsyncStorage } from 'react-native';
import Storage from '../constants/storage';
import { Auth } from '../model/auth';

const setToken = async (auth: Auth) => {
	if (auth.token) { await AsyncStorage.setItem(Storage.Token, auth.token); }
};

const removeToken = async () => {
	await AsyncStorage.removeItem(Storage.Token);
};

export {
	setToken,
	removeToken,
};
