import React from "react";
import {
	StyleSheet,
	TouchableOpacity,
	View,
	Dimensions,
	ScrollView,
} from "react-native";
import { Text, Icon } from "@ui-kitten/components";
import { SafeAreaLayout } from "../../components/ui-kitten-kit/safe-area-layout.component";
import Colors from "../../constants/color";
import { connect } from "react-redux";
import { ReduxState } from "../../reduxers/reducers";
import { CheckoutResponse } from "../../model/order";
import { BaseState } from "../../model/state";

interface MainProps {
	navigation: any;
	checkoutState: BaseState<CheckoutResponse>;
}

const screenWidth = Dimensions.get('window').width;

const PaymentDetailEmoneyScreen = (props: MainProps): React.ReactElement => {
	const {
		navigation,
		checkoutState: { data },
	} = props;

	const renderPaymentDetail = () => (
		<View style={styles.paymentDetailContainer}>
			<View style={styles.rowContainer}>
				<Text style={styles.titleText}>Rincian Pembayaran</Text>
			</View>
			<View style={styles.divider} />
			<View style={styles.statusContainer}>
				<View style={styles.imageContainer}>
					<Icon
						name="checkmark-circle-2-outline"
						style={styles.imageStyle}
						fill={Colors.Green}
					/>
				</View>
				<View style={styles.columnContainer}>
					<Text>Transaksi Sukses!</Text>
					<Text>01 JUL 2020, 20:00</Text>
				</View>
			</View>
			<View style={styles.divider} />
			<View style={[styles.rowContainer, styles.marginBottom12]}>
				<Text style={styles.rowContainer}>Status Pembayaran</Text>
				<Text style={[styles.textBoldOrange, styles.textRight]}>Pending</Text>
			</View>
			<View style={[styles.rowContainer, styles.marginBottom12]}>
				<Text style={styles.rowContainer}>Produk</Text>
				<Text
					style={[styles.textBold, styles.textRight]}
				>{`${data?.productItem.product.name} - ${data?.productItem.name}`}</Text>
			</View>
			{data?.productItem.product.destination.map((item, idx) => (
				<View style={[styles.rowContainer, styles.marginBottom12]}>
					<Text style={styles.rowContainer}>{item.label}</Text>
					<Text style={[styles.textBold, styles.textRight]}>
						{data.destinations[idx]}
					</Text>
				</View>
			))}
			<View style={[styles.rowContainer, styles.marginVertical12]}>
				<Text
					style={[styles.textBold, styles.textCenter]}
				>{`Rincian pembayaran telah dikirimkan ke ${data?.productItem.product.destination
					.map((item, idx) => {
						if (item.label.toLowerCase() === "email") {
							return data.destinations[idx];
						}
					})
					.join("")}`}</Text>
			</View>
		</View>
	);

	return (
		<SafeAreaLayout style={styles.safeArea} insets="top">
			<ScrollView>
				<View style={styles.headerContainer}>
					<TouchableOpacity
						style={styles.leftColumn}
						onPress={() => navigation.navigate("home")}
					>
						<Text style={styles.permalinkText}>Halaman utama</Text>
					</TouchableOpacity>
					<View style={styles.rightColumn} />
				</View>
				{renderPaymentDetail()}
			</ScrollView>
		</SafeAreaLayout>
	);
};

const styles = StyleSheet.create({
	safeArea: {
		flex: 1,
		backgroundColor: Colors.Grey2
	},
	headerContainer: {
		paddingVertical: 12,
		paddingHorizontal: 16,
		flexDirection: "row",
		height: 45,
	},
	leftColumn: {
		flex: 5,
	},
	rightColumn: {
		flex: 5,
		textAlign: "right",
	},
	permalinkText: {
		color: Colors.BlueOne,
	},
	paymentDetailContainer: {
		backgroundColor: Colors.White,
		paddingHorizontal: 16,
		paddingVertical: 14,
		flexDirection: "column",
	},
	rowContainer: {
		flexDirection: "row",
		flex: 1,
	},
	columnContainer: {
		flexDirection: "column",
	},
	titleText: {
		fontSize: 16,
		fontWeight: "600",
		lineHeight: 24,
	},
	divider: {
		backgroundColor: Colors.Grey5,
		width: "100%",
		height: 1,
		marginVertical: 16,
	},
	statusContainer: {
		flexDirection: "row",
		flex: 1,
		alignItems: "center",
		justifyContent: "center",
	},
	imageContainer: {
		alignItems: "flex-end",
		marginRight: 10,
	},
	imageStyle: {
		height: 30,
		width: 30,
	},
	textCenter: {
		textAlign: "center",
	},
	textRight: {
		textAlign: "right",
	},
	textBold: {
		fontWeight: "600",
		lineHeight: 24,
	},
	textBoldOrange: {
		fontWeight: "600",
		lineHeight: 24,
		color: Colors.Orange,
	},
	textPaymentCode: {
		fontWeight: "600",
		marginTop: 8,
		marginBottom: 30,
	},
	textPrice: {
		color: Colors.Orange,
		fontWeight: "600",
		textAlign: "center",
		marginVertical: 8,
	},
	textPrevPrice: {
		marginBottom: 16,
		fontSize: 13,
		textDecorationLine: "line-through",
		color: Colors.Grey,
	},
	marginBottom12: {
		marginBottom: 12,
	},
	marginVertical12: {
		marginVertical: 12,
	},
	paymentInfoContainer: {
		backgroundColor: Colors.White,
		marginTop: 8,
		padding: 16,
	},
});

const mapStateToProps = (state: ReduxState) => ({
	...state,
	checkoutState: state.order.checkout,
});

const mapDispatchToProps = (dispatch: any) => ({});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(PaymentDetailEmoneyScreen);
