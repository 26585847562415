import React from 'react';
import { AppearanceProvider } from 'react-native-appearance';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { ApplicationProvider, IconRegistry } from '@ui-kitten/components';
import { EvaIconsPack } from '@ui-kitten/eva-icons';
import { AppLoading, LoadFontsTask, Task } from './app-loading.component';
import { appMappings, appThemes } from './app-theming';
import { AppIconsPack } from './app-icons-pack';
import { StatusBar } from '../components/ui-kitten-kit/status-bar.component';
import { SplashImage } from '../components/ui-kitten-kit/splash-image.component';
import { AppNavigator } from '../navigation/app.navigator';
import { AppStorage } from '../services/app-storage.service';
import { Mapping, Theme, Theming } from '../services/theme.service';
import thunkMiddleware from 'redux-thunk';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import { createLogger } from 'redux-logger';
import { rootReducer } from '../reduxers/reducers';
import SnackbarComponent, { snackbarRef } from '../components/snackbar';
import ENV from '../utils/environment';

const middlewares: any = [];
middlewares.push(thunkMiddleware);
if (ENV.DEBUG) { middlewares.push(createLogger()); }
const store = createStore(rootReducer, applyMiddleware(...middlewares));

const loadingTasks: Task[] = [
	// Should be used it when running Expo.
	// In Bare RN Project this is configured by react-native.config.js
	() => LoadFontsTask({
		'sourceSansPro-regular': require('../assets/fonts/SourceSansPro-Regular.ttf'),
	}),
	() => AppStorage.getMapping(defaultConfig.mapping).then(result => ['mapping', result]),
	() => AppStorage.getTheme(defaultConfig.theme).then(result => ['theme', result]),
];

const defaultConfig: { mapping: Mapping, theme: Theme } = {
	mapping: 'eva',
	theme: 'light',
};

const App = ({ mapping, theme }): React.ReactElement => {

	const [mappingContext, currentMapping] = Theming.useMapping(appMappings, mapping);
	const [themeContext, currentTheme] = Theming.useTheming(appThemes, mapping, theme);

	return (
		<React.Fragment>
			<Provider store={store}>
				<IconRegistry icons={[EvaIconsPack, AppIconsPack]}/>
				<AppearanceProvider>
					<ApplicationProvider {...currentMapping} theme={currentTheme}>
						<Theming.MappingContext.Provider value={mappingContext}>
							<Theming.ThemeContext.Provider value={themeContext}>
								<SafeAreaProvider>
									<SnackbarComponent ref={snackbarRef} />
									<StatusBar/>
									<AppNavigator/>
								</SafeAreaProvider>
							</Theming.ThemeContext.Provider>
						</Theming.MappingContext.Provider>
					</ApplicationProvider>
				</AppearanceProvider>
			</Provider>
		</React.Fragment>
	);
};

const Splash = ({ loading }): React.ReactElement => (
	<SplashImage
		loading={loading}
		source={require('../assets/images/image-splash.png')}
	/>
);

export default (): React.ReactElement => (
	<AppLoading
		tasks={loadingTasks}
		initialConfig={defaultConfig}
		placeholder={Splash}>
		{props => <App {...props}/>}
	</AppLoading>
);
