import { StyleSheet, PixelRatio } from 'react-native';
import Colors from '../constants/color';

const createTypographyAlignmentVariant = () => {
	const variant: { [k: string]: any } = {};
	variant.TLeft = { textAlign: 'left' };
	variant.TCenter = { textAlign: 'center' };
	variant.TRight = { textAlign: 'right' };

	return variant;
};

const createTypographyColorVariant = (colors: { [k: string]: string }) => {
	const variant: { [k: string]: any } = {};
	Object.keys(colors).forEach((c) => {
		variant[`T${c}`] = { color: colors[c] };
	});

	return variant;
};

const createTypographySizeVariant = (sizes: Array<number>) => {
	const variant: { [k: string]: any } = {};
	sizes.forEach((s) => {
		variant[`TS${s}`] = {
			fontSize: s * PixelRatio.getFontScale(),
		};
	});

	return variant;
};

const VarTypography = StyleSheet.create({
	...createTypographyColorVariant(Colors),
	...createTypographyAlignmentVariant(),
	...createTypographySizeVariant([10, 12, 16, 20, 24]),
});

export default VarTypography;
