import { PagingResponse } from '../model/response/paging';

const PagingStateDefault: PagingResponse = {
	has_next: false,
	current_page: 1,
	total_pages: 1,
	total_data: 0,
	limit: 10,
};
const BaseStateDefault = {
	data: undefined,
	list: [],
	requesting: false,
	paging: PagingStateDefault,
	error: '',
};

export default BaseStateDefault;
