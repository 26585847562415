import { StyleSheet } from 'react-native';
import Colors from '../../constants/color';

const styles = StyleSheet.create({
	wrapper: {
		backgroundColor: Colors.Grey,
		position: 'absolute',
		top: 60,
		left: 0,
		right: 0,
		zIndex: 1001,
		margin: 16,
		borderRadius: 20,
		height: 50,
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 10,
		},
		shadowOpacity: 0.6,
		shadowRadius: 20,
		elevation: 5,
	},
	title: {
		fontWeight: '600',
		color: Colors.Black
	}
});

export default styles;
