import { action } from 'typesafe-actions';
import { Dispatch, AnyAction } from 'redux';
import Api, { setTokenRequestHeader, removeTokenRequestHeader } from '../apis';
import Base from './base';
import { BaseResponse } from '../../model/response/base';
import { AuthParams, User, Auth } from '../../model/auth';
import ActionTypes from '../../constants/action-types';
import { setToken, removeToken } from '../../utils/auth';
import * as Snackbar from '../../components/snackbar';

const signInWithEmailRequest = () =>
	action(ActionTypes.auth.SIGN_IN_WITH_EMAIL_REQUEST);
const signInWithEmailFailure = (error: string) =>
	action(ActionTypes.auth.SIGN_IN_WITH_EMAIL_FAILURE, error);
const signInWithEmailSuccess = (data: User) =>
	action(ActionTypes.auth.SIGN_IN_WITH_EMAIL_SUCCESS, data);

const signInWithEmail = (data: AuthParams, navigation: Function, resetState: Function) => async (
	dispatch: Dispatch<AnyAction>,
) => {
	dispatch(signInWithEmailRequest());
	return Api.Auth.login(data)
		.then(async (response) => {
			const auth: Auth = response.data.data;
			if (response.data.success) {
				dispatch(signInWithEmailSuccess(response.data));
				navigation();
				setTokenRequestHeader(auth.token);
			}
		})
		.catch(async (error: any) => {
			Base.ErrorResponse(error, dispatch, signInWithEmailFailure);
			resetState();
		});
};

const signUpWithEmailRequest = () => action(ActionTypes.auth.SIGN_UP_REQUEST);
const signUpWithEmailFailure = (error: string) =>
	action(ActionTypes.auth.SIGN_UP_FAILURE, error);
const signUpWithEmailSuccess = (data: User) =>
	action(ActionTypes.auth.SIGN_UP_SUCCESS, data);

const signUpWithEmail = (
	data: AuthParams,
) => (dispatch: Dispatch<AnyAction>) => {
	Base.CallAction(
		dispatch,
		Api.Auth.signup(data),
		signUpWithEmailRequest,
		(response: BaseResponse<User>) => signUpWithEmailSuccess(response.data),
		signUpWithEmailFailure,
	);
};

const signOutSuccess = () => action(ActionTypes.auth.SIGN_OUT_SUCCESS);
const signOut = () => (dispatch: Dispatch<AnyAction>) => {
	removeToken();
	dispatch(signOutSuccess());
}

export default {
	signInWithEmail,
	signUpWithEmail,
	signOut
};
