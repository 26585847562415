import * as React from 'react';
import { View, Text } from 'react-native';
import Styles from './snackbar.style';
import styles from 'src/styles';

interface States {
	visible: boolean;
	title: string;
	mode: Array<string> | undefined;
}

export default class SnackbarComponent extends React.Component<any, States> {
	constructor(props: any) {
		super(props);
		this.state = {
			visible: false,
			title: '',
			mode: undefined,
		};
	}

	show = (title: string, duration: number, mode?: Array<string>) => {
		this.setState(
			{
				title,
				mode,
				visible: true,
			},
			() => {
				setTimeout(() => {
					this.setState({
						visible: false,
						title: '',
						mode: [''],
					});
				}, duration);
			},
		);
	};

	render() {
		const { visible, title, mode } = this.state;
		if (visible) {
			return (
				<View style={[Styles.wrapper, mode ? { backgroundColor: mode[0] } : {}]}>
					<View style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
						<Text style={[Styles.title, mode ? { color: mode[1] } : {}]}>
							{title}
						</Text>
					</View>
				</View>
			);
		}
		return null;
	}
}
