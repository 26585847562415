import * as React from 'react';
import HomeScreen from '../scenes/home';
import PaymentScreen from '../scenes/payment';
import PaymentDetailBankScreen from '../scenes/payment-detail/bank';
import PaymentDetailEmoneyScreen from '../scenes/payment-detail/emoney';
import { ProfileScreen } from '../scenes/profile';
import { TransactionHistoryScreen } from '../scenes/payment-detail/transaction-history';
import AuthScreen from '../scenes/auth';
import StackNavigation from './stack';
import LogoHeader from '../components/header/with-logo';
import * as Navigation from '../utils/navigation';
import { connect } from 'react-redux';
import { User } from '../model/auth';
import { ReduxState } from '../reduxers/reducers';

const HomeStackNavigation = [
		{
			name: 'home',
			component: HomeScreen,
			headerOptions: LogoHeader,
		},
		{
			name: 'payment',
			component: PaymentScreen,
			headerOptions: LogoHeader,
		},
		{
			name: 'paymentDetailBank',
			component: PaymentDetailBankScreen,
			headerOptions: LogoHeader,
		},
		{
			name: 'paymentDetailEmoney',
			component: PaymentDetailEmoneyScreen,
			headerOptions: LogoHeader,
		},
		{
			name: 'profile',
			component: ProfileScreen,
			headerOptions: LogoHeader,
		},
		{
			name: 'transactionHistory',
			component: TransactionHistoryScreen,
			headerOptions: LogoHeader,
		},
		{
			name: 'auth',
			component: AuthScreen,
			headerOptions: LogoHeader,
		},
	];

interface MainProps {
	user: User;
	isLoggedIn: boolean;
}

const HomeNavigation = (props: MainProps) => {
	const { isLoggedIn, user } = props;
	React.useEffect(() => {
		Navigation.init(isLoggedIn);
	});
	return <StackNavigation navigations={HomeStackNavigation} user={user} isLoggedIn={isLoggedIn}  />;
};

const mapStateToProps = (state: ReduxState) => ({
	...state,
	user: state.auth.data,
	isLoggedIn: state.auth.isLoggedIn,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(HomeNavigation);
