import React, { useState, useRef, useEffect } from 'react';
import {
	StyleSheet,
	TouchableOpacity,
	View,
	Image,
	Dimensions,
	ScrollView,
	ListRenderItemInfo,
} from 'react-native';
import {
	Text,
	Input,
	List,
	Icon,
	Modal,
	Button,
	Layout,
} from '@ui-kitten/components';
import Colors from '../constants/color';
import { DEFAULT_CONCAT } from '../constants';
import { formatNumber, concatText } from '../utils';
import { connect } from 'react-redux';
import { ReduxState } from '../reduxers/reducers';
import Actions from '../reduxers/actions';
import { Product, ProductItem } from '../model/product';
import { PaymentMethodsResponse, PaymentMethodQuery, CheckoutParams } from '../model/order';
import { BaseState } from '../model/state';
import * as Snackbar from '../components/snackbar';

const screenWidth = Dimensions.get('window').width;
const screenHeight = Dimensions.get('window').height;

interface MainProps {
	navigation: any;
	productItems: BaseState<ProductItem>;
	product: Product;
	paymentChannels: BaseState<PaymentMethodsResponse>;
	getProductItems: (data: { productId: number }) => Promise<any>;
	paymentMethods: (data: PaymentMethodQuery) => Promise<any>;
	checkout: (data: CheckoutParams) => Promise<any>;
}

const PaymentScreen = (props: MainProps): React.ReactElement => {
	const {
		navigation,
		productItems,
		product,
		paymentChannels,
		getProductItems,
		paymentMethods,
		checkout,
	} = props;
	const [invalidFields, setInvalidFields] = useState(['']);
	const [selectedNominal, setSelectedNominal] = useState<
		ListRenderItemInfo<ProductItem> | undefined
	>(undefined);
	const [selectedChannel, setSelectedChannel] = useState<
		ListRenderItemInfo<PaymentMethodsResponse> | undefined
	>(undefined);
	const [visible, setVisible] = useState(false);
	const destinationValues: object = {};
	product.destination.map(item => {
		destinationValues[item.label] = {
			value: '',
			isValid: true,
		};
	});
	const [destinationState, setDestinationState] = useState(destinationValues);
	const onChangeDestination = (label: string, destination: string | number) => {
		const tempDestination = { ...destinationState };
		tempDestination[label] = {
			value: destination,
			isValid: true,
		};
		setDestinationState(tempDestination);
	};

	const onSelectChannel = (payment: ListRenderItemInfo<PaymentMethodsResponse>) => {
		if (selectedNominal) {
			setSelectedChannel(payment);
			setVisible(true);
		} else {
			Snackbar.show('Silakan memilih nominal terlebih dahulu.', Snackbar.MEDIUM, Snackbar.INFO);
		}
	};


	useEffect(() => {
		getProductItems({ productId: product.id });
		paymentMethods({productId: undefined, productItemId: undefined});
	}, []);

	const scrollRef = useRef<any>();

	const renderHeader = () => (
		<View style={styles.headerContainer}>
			<View style={styles.headerFirstCol}>
				<TouchableOpacity
					onPress={() => navigation.navigate('home')}
					style={styles.rowCenter}
				>
					<Text style={styles.backText}>Halaman Utama</Text>
				</TouchableOpacity>
				<View style={styles.rowTop}>
					<Text style={styles.itemName}>{product.name}</Text>
				</View>
			</View>
			<View style={styles.headerSecondCol}>
				<Image source={{ uri: product.image }} style={styles.imageStyle} />
			</View>
		</View>
	);

	const onCheckKeyboardType = (type: string) => {
		if (type.toLowerCase().includes('email')) { return 'email-address'; }
		if (type.toLowerCase().includes('number')) { return 'numeric'; }
		return 'default';
	};

	const setPlaceholder = (type: string) => {
		if (type.toLowerCase().includes('email')) { return 'yourname@gmail.com'; }
		if (type.toLowerCase().includes('number')) { return '1234567890'; }
		return 'Place your Text';
	};

	const validateDestination = () => {
		let isValid = true;
		let invalidFields: Array<string> = [];
		const tempDestination = { ...destinationState };
		product.destination.map(item => {
			if (item.isRequired && (destinationState[item.label].value as string).length === 0) {
				isValid = false;
				invalidFields = [...invalidFields, item.label];
				tempDestination[item.label] = {
					value: destinationState[item.label].value,
					isValid: false,
				};
			}
		});
		setDestinationState(tempDestination);
		setInvalidFields(invalidFields);
		return {isValid, invalidFields};
	};

	const renderDestinationSection = () => (
		<View style={styles.emailSectionContainer}>
			{product.destination.map(item => (
				<>
					<View style={styles.rowEmailSection}>
						<Text category='c2'>{item.label}</Text>
					</View>
					<View style={styles.rowEmailSection}>
						<Input
							placeholder={setPlaceholder(item.type)}
							value={destinationState[item.label].value}
							onChangeText={destination => onChangeDestination(item.label, destination)}
							status={invalidFields.includes(item.label) ? 'danger' : 'basic'}
							caption={item.isRequired ? 'required' : undefined}
							captionIcon={item.isRequired ? () => <Icon name='alert-circle-outline' /> : undefined}
							keyboardType={onCheckKeyboardType(item.type)}
						/>
					</View>
				</>
			))}
			<View style={styles.rowEmailSection}>
				<Text category='c2'>
					Tagihan pembayaran dan bukti transaksi akan kami kirimkan ke email
					diatas
				</Text>
			</View>
		</View>
	);
	const onSelectNominal = (data: ListRenderItemInfo<ProductItem>) => {
		setSelectedNominal(data);
		paymentMethods({ productId: data.item.product.id, productItemId: data.item.id });
	};
	const renderNominal = () => (
		<View style={styles.sectionContainer}>
			<View style={styles.sectionTitleContainer}>
				<Text style={styles.sectionTitle}>Nominal</Text>
			</View>
			<List
				data={productItems.list}
				contentContainerStyle={styles.listWrapper}
				numColumns={3}
				renderItem={renderNominalList}
				refreshing={productItems.requesting}
			/>
		</View>
	);
	const renderNominalList = (data: ListRenderItemInfo<ProductItem>) => (
		<TouchableOpacity
			style={
				data && selectedNominal && data.item.name === selectedNominal.item.name
					? styles.selectedListItem
					: styles.listItem
			}
			onPress={() => onSelectNominal(data)}
		>
			<View style={styles.listItemTitle}>
				<Text style={[styles.textCenter, styles.listItemTitleText]}>
					{concatText(DEFAULT_CONCAT, data.item.name)}
				</Text>
			</View>
			<View style={styles.listItemDesc}>
				<Text style={styles.textCenter}>{`Rp ${formatNumber(
					data.item.price,
				)}`}</Text>
			</View>
			{selectedNominal?.item.id === data?.item.id && (
				<View
					style={styles.quarterCircle}
				>
					<Icon
						style={styles.checkIcon}
						name='checkmark-outline'
						fill={Colors.White}
					/>
				</View>
			)}
		</TouchableOpacity>
	);
	const renderPaymentMethod = (paymentChannels: BaseState<PaymentMethodsResponse>) => (
		<View style={styles.sectionContainer}>
			<View style={styles.sectionTitleContainer}>
				<Text style={styles.sectionTitle}>Metode Pembayaran</Text>
			</View>
			<List
				data={paymentChannels.list}
				contentContainerStyle={styles.listWrapper}
				numColumns={1}
				renderItem={renderChannelList}
				refreshing={paymentChannels.requesting}
			/>
		</View>
	);
	const renderChannelList = (
		paymentChannel: ListRenderItemInfo<PaymentMethodsResponse>,
	) => (
		<TouchableOpacity
			style={styles.channelListContainer}
			onPress={() => onSelectChannel(paymentChannel)}
		>
			<View style={styles.channelListColumn1}>
				<Image
					style={{ height: 60, resizeMode: 'contain' }}
					source={{ uri: paymentChannel.item.image }}
				/>
			</View>
			<View style={styles.channelListColumn2}>
				<Text style={styles.alignLeft}>{paymentChannel.item.methodName}</Text>
			</View>
			<View style={styles.channelListColumn3}>
				{paymentChannel.item.totalPayment > 0 && (
					<>
						<Text style={styles.textOne}>Total Harga</Text>
						<Text style={styles.textTwo}>{`Rp ${formatNumber(paymentChannel.item.totalPayment)}`}</Text>
					</>
				)}
			</View>
			<View style={styles.channelListColumn4}>
				<View style={{ flexDirection: 'row', flex: 1}}>
					<Icon
						style={[styles.alignRight, styles.smallIcon]}
						name='arrow-ios-forward-outline'
					/>
				</View>
			</View>
		</TouchableOpacity>
	);

	const onConfirmOrder = (
		selectedChannel: ListRenderItemInfo<PaymentMethodsResponse> | undefined,
	) => {
		const destination: Array<string> = [];
		product.destination.map(item => {
			destination.push(destinationState[item.label].value);
		});
		const data: CheckoutParams = {
			productItemId: selectedNominal?.item.id || '',
			paymentMethodId: selectedChannel?.item.id || '',
			voucherUsed: [],
			destination,
		};
		setVisible(false);
		const validation = validateDestination();
		if (validation.isValid) {
			checkout(data);
			if (selectedChannel?.item.id === paymentChannels.list[0].id) {
				navigation.navigate('paymentDetailBank');
			} else {
				navigation.navigate('paymentDetailEmoney');
			}
			/* if (selectedChannel?.item.methodName.toLowerCase().includes('bank')) {
				navigation.navigate('paymentDetailBank');
			} else {
				navigation.navigate('paymentDetailEmoney');
			} */
		} else {
			Snackbar.show(`Required fields: ${validation.invalidFields.join(', ')}`, Snackbar.MEDIUM, Snackbar.WARNING);
			scrollRef.current?.scrollTo({ y: 0, x: 0, animated: true });
		}
	};

	return (
		<React.Fragment>
			<View style={styles.safeArea}>
				<ScrollView ref={scrollRef}>
					{renderHeader()}
					{renderDestinationSection()}
					{renderNominal()}
					{renderPaymentMethod(paymentChannels)}
				</ScrollView>
			</View>
			<Modal
				visible={visible}
				onBackdropPress={() => setVisible(false)}
				backdropStyle={styles.backdrop}
			>
				<Layout style={styles.modalContainer}>
					<View style={styles.modalPaddingWrapper}>
						<View style={styles.titleModal}>
							<Text style={styles.titleModalText}>Konfirmasi Pesanan</Text>
							<TouchableOpacity
								style={styles.titleModalIcon}
								onPress={() => setVisible(false)}
							>
								<Icon name='close-square-outline' style={styles.mediumIcon} />
							</TouchableOpacity>
						</View>
						<View style={styles.divider} />
						<View style={styles.productContainer}>
							<Text style={styles.productLeft}>Produk</Text>
							<Text style={styles.productRight}>
								{selectedNominal?.item.name}
							</Text>
						</View>
						<View style={styles.productContainer}>
							<Text style={styles.productLeft}>Harga</Text>
							<Text style={styles.priceRight}>{`Rp ${formatNumber(
								selectedChannel?.item.totalPayment || 0,
							)}`}</Text>
						</View>
						{product.destination.map(item => (
							<View style={styles.productContainer}>
								<Text style={styles.productLeft}>{item.label}</Text>
								<Text style={styles.productRight}>{destinationState[item.label].value}</Text>
							</View>
						))}
						<View style={styles.productContainer}>
							<Text style={styles.paymentMethodLeft}>Metode Pembayaran</Text>
							<Text style={styles.paymentMethodRight}>
								{selectedChannel?.item.methodName}
							</Text>
						</View>
						<View style={styles.titleModal}>
							<Button
								style={styles.button}
								onPress={() => onConfirmOrder(selectedChannel)}
							>
								Konfirmasi Pesanan
							</Button>
						</View>
					</View>
				</Layout>
			</Modal>
		</React.Fragment>
	);
};

const styles = StyleSheet.create({
	safeArea: {
		flex: 1,
		backgroundColor: Colors.Grey2,
	},
	headerContainer: {
		flexDirection: 'row',
		height: 104,
	},
	headerFirstCol: {
		flex: 0.7,
	},
	headerSecondCol: {
		flex: 0.3,
		justifyContent: 'center',
		alignItems: 'center',
	},
	rowCenter: {
		flex: 1,
		justifyContent: 'center',
		marginLeft: 16,
	},
	rowTop: {
		flex: 1,
		justifyContent: 'flex-start',
		marginLeft: 16,
	},
	backText: {
		color: Colors.BlueOne,
	},
	itemName: {
		color: Colors.Black,
		fontWeight: '900',
	},
	imageStyle: {
		width: 64,
		height: 64,
		resizeMode: 'contain',
	},
	emailSectionContainer: {
		width: '100%',
		backgroundColor: Colors.White,
		paddingVertical: 16,
	},
	rowEmailSection: {
		flex: 1,
		justifyContent: 'center',
		marginHorizontal: 16,
		marginBottom: 16,
	},
	sectionContainer: {
		marginVertical: 8,
		backgroundColor: Colors.White,
	},
	listWrapper: {
		paddingHorizontal: 12,
		backgroundColor: Colors.White,
	},
	sectionTitleContainer: {
		marginVertical: 20,
		marginHorizontal: 16,
	},
	sectionTitle: {
		fontWeight: '600',
		fontSize: 16,
		lineHeight: 24,
	},
	listItem: {
		borderRadius: 3,
		backgroundColor: Colors.Grey3,
		flex: 1,
		marginHorizontal: 4,
		marginVertical: 12,
		maxWidth: (screenWidth - 24) / 3 - 8,
	},
	selectedListItem: {
		borderRadius: 3,
		backgroundColor: Colors.Grey3,
		flex: 1,
		marginHorizontal: 4,
		marginVertical: 12,
		maxWidth: (screenWidth - 24) / 3 - 8,
		borderWidth: 2,
		borderColor: Colors.Orange,
	},
	textCenter: {
		textAlign: 'center',
	},
	listItemTitle: {
		marginHorizontal: 8,
		marginVertical: 16,
		height: 40,
		justifyContent: 'center',
	},
	listItemTitleText: {
		fontSize: 14,
		lineHeight: 21,
		fontWeight: '600',
	},
	listItemDesc: {
		marginBottom: 16,
		marginTop: 5,
		marginHorizontal: 8,
	},
	channelListContainer: {
		flexDirection: 'row',
		flex: 1,
		backgroundColor: Colors.Grey4,
		borderRadius: 3,
		paddingVertical: 5,
		marginBottom: 7,
	},
	channelListColumn1: {
		flex: 3,
	},
	channelListColumn2: {
		flex: 3,
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'flex-start',
	},
	channelListColumn3: {
		flex: 3,
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'flex-end',
	},
	textOne: {
		fontSize: 16,
		lineHeight: 24,
		color: Colors.Grey6,
	},
	textTwo: {
		fontSize: 16,
		lineHeight: 24,
		color: Colors.Orange,
	},
	channelListColumn4: {
		flex: 1,
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'flex-end',
	},
	alignLeft: {
		marginLeft: 6,
		fontSize: 16,
		lineHeight: 24,
	},
	alignRight: {
		alignItems: 'flex-end',
		flex: 2,
		marginRight: 10,
	},
	smallIcon: {
		width: 10,
	},
	mediumIcon: {
		width: 20,
	},
	modalContainer: {
		bottom: 0,
		top: screenHeight / 2 - 140,
		height: 280,
		width: screenWidth,
		alignItems: 'center',
		backgroundColor: Colors.White,
	},
	modalPaddingWrapper: {
		width: '100%',
		paddingHorizontal: 16,
	},
	backdrop: {
		backgroundColor: 'rgba(0, 0, 0, 0.5)',
	},
	titleModal: {
		flexDirection: 'row',
		width: '100%',
		paddingVertical: 14,
	},
	titleModalText: {
		alignItems: 'flex-start',
		flex: 8,
	},
	titleModalIcon: {
		alignItems: 'flex-end',
		flex: 2,
	},
	divider: {
		backgroundColor: Colors.Grey5,
		width: '100%',
		height: 1,
	},
	productContainer: {
		flexDirection: 'row',
		flex: 1,
		paddingTop: 14,
	},
	productLeft: {
		flex: 4,
		textAlign: 'left',
	},
	productRight: {
		alignItems: 'flex-end',
		flex: 6,
		textAlign: 'right',
		fontWeight: '600',
	},
	priceRight: {
		alignItems: 'flex-end',
		flex: 6,
		textAlign: 'right',
		fontWeight: '600',
		color: Colors.Orange,
	},
	paymentMethodLeft: {
		flex: 6,
		textAlign: 'left',
	},
	paymentMethodRight: {
		alignItems: 'flex-end',
		flex: 4,
		textAlign: 'right',
		fontWeight: '600',
	},
	button: {
		flex: 1,
		backgroundColor: Colors.BlueOne,
	},
	checkIcon: {
		width: 15,
		height: 15,
		top: 0,
		right: 0,
		position: 'absolute',
	},
	quarterCircle: {
		backgroundColor: Colors.Orange,
		position: 'absolute',
		width: 20,
		height: 20,
		borderBottomLeftRadius: 20,
		top: 0,
		right: 0,
		zIndex: 1002,
	},
	columnCenter: {
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
	},
});


const mapStateToProps = (state: ReduxState) => ({
	...state,
	productItems: state.product.productItem,
	product: state.product.product.data,
	paymentChannels: state.order.paymentMethods,
});

const mapDispatchToProps = (dispatch: any) => ({
	getProductItems: (data: { productId: number }) => dispatch(Actions.product.getProductItems(data)),
	paymentMethods: (data: PaymentMethodQuery) => dispatch(Actions.order.paymentMethods(data)),
	checkout: (data: CheckoutParams) => dispatch(Actions.order.checkout(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentScreen);
