import Button from './button';
import Form from './form';
import Layout from './layout';
import Space from './space';
import Typography from './typography';

export default {
	Button,
	Form,
	Layout,
	Space,
	Typography,
};
