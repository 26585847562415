const GET_ALL_PRODUCTS_REQUEST = 'GET_ALL_PRODUCTS_REQUEST';
const GET_ALL_PRODUCTS_SUCCESS = 'GET_ALL_PRODUCTS_SUCCESS';
const GET_ALL_PRODUCTS_FAILURE = 'GET_ALL_PRODUCTS_FAILURE';

const GET_PRODUCT_ITEMS_REQUEST = 'GET_PRODUCT_ITEMS_REQUEST';
const GET_PRODUCT_ITEMS_SUCCESS = 'GET_PRODUCT_ITEMS_SUCCESS';
const GET_PRODUCT_ITEMS_FAILURE = 'GET_PRODUCT_ITEMS_FAILURE';

const GET_PRODUCT_REQUEST = 'GET_PRODUCT_REQUEST';
const GET_PRODUCT_SUCCESS = 'GET_PRODUCT_SUCCESS';
const GET_PRODUCT_FAILURE = 'GET_PRODUCT_FAILURE';

const SELECT_PRODUCT_SUCCESS = 'SELECT_PRODUCT_SUCCESS';

export default {
	GET_ALL_PRODUCTS_REQUEST,
	GET_ALL_PRODUCTS_SUCCESS,
	GET_ALL_PRODUCTS_FAILURE,

	GET_PRODUCT_ITEMS_REQUEST,
	GET_PRODUCT_ITEMS_SUCCESS,
	GET_PRODUCT_ITEMS_FAILURE,

	GET_PRODUCT_REQUEST,
	GET_PRODUCT_SUCCESS,
	GET_PRODUCT_FAILURE,

	SELECT_PRODUCT_SUCCESS,
}
