import React from 'react';
import { StyleSheet, View, Text, Dimensions, Image, ScrollView } from 'react-native';
import { SafeAreaLayout } from '../components/ui-kitten-kit/safe-area-layout.component';
import { LayoutGridList } from '../components/ui-kitten-kit/layout-grid-list.component';
import * as images from '../constants/images';
import { connect } from 'react-redux';
import { ReduxState } from '../reduxers/reducers';
import Actions from '../reduxers/actions';
import { ProductKind, Product } from '../model/product';
import Colors from '../constants/color';

const screenWidth = Dimensions.get('window').width;
const bannerRatio = 138 / 360;

interface MainProps {
	navigation: any;
	productKinds: Array<ProductKind> | undefined;
	getAllProducts: () => Promise<any>;
	selectProduct: (data: Product) => Promise<any>;
	clearPaymentMethod: () => void;
}

const HomeScreen = (props: MainProps): React.ReactElement => {
	const { navigation, getAllProducts, selectProduct, clearPaymentMethod, productKinds } = props;

	React.useEffect(() => {
		getAllProducts();
	}, []);

	const onSelectProduct = (data: Product) => {
		selectProduct(data);
		clearPaymentMethod();
		navigation.navigate('payment');
	};

	const renderFooter = () => (
		<View style={styles.footerContainer}>
			<View style={styles.footerImageContainer}>
				<Image source={images.footerLogo} style={styles.footerImageBody} />
			</View>
			<View style={styles.footerTextContainer}>
				<Text style={styles.textCenter}>All right reserved 2020</Text>
			</View>
		</View>
	);

	return (
		<SafeAreaLayout
			style={styles.safeArea}
			insets='top'>
				<ScrollView>
					<Image source={images.banner} style={{ width: screenWidth , height: screenWidth * bannerRatio }} resizeMode='contain' />
					{productKinds?.map(item => (
						<View style={styles.container}>
							<Text style={styles.title}>
								{item.name}
							</Text>
							<LayoutGridList data={item.products} callback={onSelectProduct} />
						</View>
					))}
					{renderFooter()}
				</ScrollView>
		</SafeAreaLayout>
	);
};

const styles = StyleSheet.create({
	safeArea: {
		flex: 1,
	},
	title: {
		fontSize: 18,
		lineHeight: 27,
		fontWeight: 'bold',
		marginVertical: 22,
		marginHorizontal: 4,
	},
	container: {
		marginHorizontal: 12,
	},
	footerContainer: {
		backgroundColor: Colors.Theme,
		flexDirection: 'column',
		marginTop: 100,
	},
	footerImageContainer: {
		height: 180,
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
	},
	footerTextContainer: {
		paddingVertical: 20,
		flex: 1,
	},
	footerImageBody: {
		width: 104,
		height: 104,
	},
	textCenter: {
		color: Colors.White,
		textAlign: 'center',
	},
});

const mapStateToProps = (state: ReduxState) => ({
	...state,
	productKinds: state.product.productKind.list,
});

const mapDispatchToProps = (dispatch: any) => ({
	getAllProducts: () => dispatch(Actions.product.getAllProducts()),
	selectProduct: (data: Product) => dispatch(Actions.product.selectProduct(data)),
	clearPaymentMethod: () => dispatch(Actions.order.clearPaymentMethod()),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeScreen);
