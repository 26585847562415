import { action } from 'typesafe-actions';
import { Dispatch, AnyAction } from 'redux';
import Api from '../apis';
import Base from './base';
import { BaseResponse } from '../../model/response/base';
import { CheckoutParams, PaymentMethodQuery, CheckoutResponse, PaymentMethodsResponse } from '../../model/order';
import ActionTypes from '../../constants/action-types';

const checkoutRequest = () =>
	action(ActionTypes.order.CHECKOUT_REQUEST);
const checkoutFailure = (error: string) =>
	action(ActionTypes.order.CHECKOUT_FAILURE, error);
const checkoutSuccess = (data: CheckoutResponse) =>
	action(ActionTypes.order.CHECKOUT_SUCCESS, data);

const checkout = (data: CheckoutParams) => (
	dispatch: Dispatch<AnyAction>,
) => {
	Base.CallAction(
		dispatch,
		Api.Order.checkout(data),
		checkoutRequest,
		(response: BaseResponse<CheckoutResponse>) => checkoutSuccess(response.data),
		checkoutFailure,
	);
};

const paymentMethodsRequest = () =>
	action(ActionTypes.order.PAYMENT_METHODS_REQUEST);
const paymentMethodsFailure = (error: string) =>
	action(ActionTypes.order.PAYMENT_METHODS_FAILURE, error);
const paymentMethodsSuccess = (data: PaymentMethodsResponse) =>
	action(ActionTypes.order.PAYMENT_METHODS_SUCCESS, data);

const paymentMethods = (data: PaymentMethodQuery) => (
	dispatch: Dispatch<AnyAction>,
) => {
	Base.CallAction(
		dispatch,
		Api.Order.paymentMethods(data),
		paymentMethodsRequest,
		(response: BaseResponse<PaymentMethodsResponse>) => paymentMethodsSuccess(response.data),
		paymentMethodsFailure,
	);
};

const clearPaymentMethod = () => (dispatch: Dispatch<AnyAction>) => {
	dispatch(action(ActionTypes.order.CLEAR_PAYMENT_METHOD_SUCCESS));
}

export default {
	checkout,
	paymentMethods,
	clearPaymentMethod,
};
