import { Reducer } from 'redux';
import ActionTypes from '../../constants/action-types';
import { ProductState, ProductStateDefault } from '../../model/state';

const initialState: ProductState = { ...ProductStateDefault };

const reducer: Reducer<any> = (state: ProductState = initialState, action): ProductState => {
	switch (action.type) {
		case ActionTypes.product.GET_ALL_PRODUCTS_REQUEST: {
			return {
				...state,
				productKind: {
					...state.productKind,
					requesting: true,
					error: ''
				}
			}
		}
		case ActionTypes.product.GET_PRODUCT_REQUEST: {
			return {
				...state,
				product: {
					...state.product,
					requesting: true,
					error: ''
				}
			}
		}
		case ActionTypes.product.GET_PRODUCT_ITEMS_REQUEST: {
			return {
				...state,
				productItem: {
					...state.productItem,
					requesting: true,
					error: ''
				}
			}
		}
		case ActionTypes.product.GET_ALL_PRODUCTS_FAILURE: {
			return {
				...state,
				productKind: {
					...state.productKind,
					requesting: false,
					error: action.payload
				}
			}
		}
		case ActionTypes.product.GET_PRODUCT_FAILURE: {
			return {
				...state,
				product: {
					...state.product,
					requesting: false,
					error: action.payload
				}
			}
		}
		case ActionTypes.product.GET_PRODUCT_ITEMS_FAILURE: {
			return {
				...state,
				productItem: {
					...state.productItem,
					requesting: false,
					error: action.payload,
					list: []
				}
			}
		}
		case ActionTypes.product.GET_ALL_PRODUCTS_SUCCESS: {
			return {
				...state,
				productKind: {
					...state.productKind,
					requesting: false,
					list: action.payload
				}
			}
		}
		case ActionTypes.product.GET_PRODUCT_SUCCESS: {
			return {
				...state,
				product: {
					...state.product,
					requesting: false,
					data: action.payload
				}
			}
		}
		case ActionTypes.product.GET_PRODUCT_ITEMS_SUCCESS: {
			return {
				...state,
				productItem: {
					...state.productItem,
					requesting: false,
					list: action.payload
				}
			}
		}
		case ActionTypes.product.SELECT_PRODUCT_SUCCESS: {
			return {
				...state,
				product: {
					...state.product,
					data: action.payload
				}
			}
		}
		default: {
			return state;
		}
	}
}

export default reducer;
