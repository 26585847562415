/* eslint-disable import/no-mutable-exports */
import app from '../../app.json';

const STAGING = 'metazone-staging';
const PROD = 'metazone';
const appname = app.expo.name;

const ENVS = {
	DEV: {
		API_URL: 'https://metazone-stag.apps.evosesports.com/v1',
		ENABLE_SENTRY: false,
		FACEBOOK_APP_ID: '741804876285688',
		DEBUG: true,
	},
	STAGING: {
		API_URL: 'https://metazone-stag.apps.evosesports.com/v1',
		ENABLE_SENTRY: true,
		FACEBOOK_APP_ID: '741804876285688',
		DEBUG: false,
	},
	PROD: {
		API_URL: 'https://metazone-prod.apps.evosesports.com/v1',
		ENABLE_SENTRY: true,
		FACEBOOK_APP_ID: '741804876285688',
		DEBUG: false,
	},
};

let ENV = ENVS.DEV;
if (appname === STAGING) { ENV = ENVS.STAGING; }
if (appname === PROD) { ENV = ENVS.PROD; }

export default ENV;
