import React from 'react';
import { StyleSheet, TouchableOpacity, View, Image, ScrollView, Dimensions } from 'react-native';
import { Text} from '@ui-kitten/components';
import { SafeAreaLayout } from '../../components/ui-kitten-kit/safe-area-layout.component';
import Colors from '../../constants/color';
import * as Images from '../../constants/images';
import FaqCard from '../../components/faq-card';
import { connect } from 'react-redux';
import { ReduxState } from '../../reduxers/reducers';
import { CheckoutResponse } from '../../model/order';
import { BaseState } from '../../model/state';
import { formatNumber } from '../../utils';

interface MainProps {
	navigation: any;
	checkoutState: BaseState<CheckoutResponse>;
}

const screenWidth = Dimensions.get('window').width;

const paymentFaq = [{
	q: 'ATM Mandiri',
	a: ['1. test', '2. test 2', '3. test 3'],
	group: 'payment'
},
{
	q: 'ATM Permata',
	a: ['1. test', '2. test 2', '3. test 3'],
	group: 'payment'
},
{
	q: 'OVO',
	a: ['1. test', '2. test 2', '3. test 3'],
	group: 'payment'
}];


const PaymentDetailBankScreen = (props: MainProps): React.ReactElement => {

	const { navigation, checkoutState: { data } } = props;

	const renderPaymentDetail = () => (
		<View style={styles.paymentDetailContainer}>
			<View style={styles.rowContainer}>
				<Text style={styles.titleText}>Rincian Pembayaran</Text>
			</View>
			<View style={styles.divider} />
			<View style={styles.boxShadowContainer}>
				<Image style={styles.paymentChannelImage} source={Images.testImage1} />
				<Text style={styles.textCenter}>Kode unik pembayaran</Text>
				<Text style={[styles.textCenter, styles.textPaymentCode]}>
					1234 1234 1234 1234
				</Text>
				<Text style={styles.textCenter}>Jumlah Tagihan</Text>
				<Text style={styles.textPrice}>{`Rp ${formatNumber(
					data?.total || 0
				)}`}</Text>
				<Text style={styles.textPrevPrice}>{`Rp ${formatNumber(
					data?.subTotal || 0
				)}`}</Text>
			</View>
			<View style={[styles.rowContainer, styles.marginBottom12]}>
				<Text style={styles.rowContainer}>Status Pembayaran</Text>
				<Text style={[styles.textBoldOrange, styles.textRight]}>Pending</Text>
			</View>
			<View style={[styles.rowContainer, styles.marginBottom12]}>
				<Text style={styles.rowContainer}>Produk</Text>
				<Text
					style={[styles.textBold, styles.textRight]}
				>{`${data?.productItem.product.name} - ${data?.productItem.name}`}</Text>
			</View>
			{data?.productItem.product.destination.map((item, idx) => (
				<View style={[styles.rowContainer, styles.marginBottom12]}>
					<Text style={styles.rowContainer}>{item.label}</Text>
					<Text style={[styles.textBold, styles.textRight]}>
						{data.destinations[idx]}
					</Text>
				</View>
			))}
			<View style={[styles.rowContainer, styles.marginVertical12]}>
				<Text
					style={[styles.textBold, styles.textCenter]}
				>{`Rincian pembayaran telah dikirimkan ke ${data?.productItem.product.destination.map(
					(item, idx) => {
						if (item.label.toLowerCase() === "email") {
							return data.destinations[idx];
						}
					}
				).join('')}`}</Text>
			</View>
		</View>
	);

	const renderPaymentMetodInfo = () => (
		<View style={styles.paymentInfoContainer}>
			<View style={styles.rowContainer}>
				<Text style={styles.titleText}>Cara Pembayaran</Text>
			</View>
			<View style={styles.divider} />
			{paymentFaq.map(item => (
				<FaqCard faq={item} />
			))}
		</View>
	);

  return (
		<SafeAreaLayout
      style={styles.safeArea}
      insets='top'>
				<ScrollView>
					<View style={styles.headerContainer}>
						<TouchableOpacity style={styles.leftColumn} onPress={() => navigation.navigate('home')}>
							<Text style={styles.permalinkText}>
								Halaman utama
							</Text>
						</TouchableOpacity>
						<Text style={styles.rightColumn}>
							Batalkan pesanan
						</Text>
					</View>
					{renderPaymentDetail()}
					{renderPaymentMetodInfo()}
				</ScrollView>
    </SafeAreaLayout>
	);
};

const styles = StyleSheet.create({
  safeArea: {
		flex: 1,
		backgroundColor: Colors.Grey2
	},
	headerContainer: {
		paddingVertical: 12,
		paddingHorizontal: 16,
		flexDirection: 'row',
		height: 45
	},
	leftColumn: {
		flex: 5,
	},
	rightColumn: {
		flex: 5,
		textAlign: 'right'
	},
	permalinkText: {
		color: Colors.BlueOne
	},
	paymentDetailContainer: {
		backgroundColor: Colors.White,
		paddingHorizontal: 16,
		paddingVertical: 14,
		flexDirection: 'column',
	},
	rowContainer: {
		flexDirection: 'row',
		flex: 1
	},
	titleText: {
		fontSize: 16,
		fontWeight: '600',
		lineHeight: 24
	},
	divider: {
		backgroundColor: Colors.Grey5,
		width: '100%',
		height: 1,
		marginVertical: 16
	},
	boxShadowContainer: {
		shadowColor: "#000",
		shadowOpacity: 0.2,
		shadowRadius: 5,
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: 3,
		marginBottom: 24,
	},
	paymentChannelImage: {
		marginVertical: 25,
		height: 30,
		width: 30,
	},
	textCenter: {
		textAlign: 'center'
	},
	textRight: {
		textAlign: 'right'
	},
	textBold: {
		fontWeight: '600',
		lineHeight: 24
	},
	textBoldOrange: {
		fontWeight: '600',
		lineHeight: 24,
		color: Colors.Orange
	},
	textPaymentCode: {
		fontWeight: '600',
		marginTop: 8,
		marginBottom: 30
	},
	textPrice: {
		color: Colors.Orange,
		fontWeight: '600',
		textAlign: 'center',
		marginVertical: 8
	},
	textPrevPrice: {
		marginBottom: 16,
		fontSize: 13,
		textDecorationLine: 'line-through',
		color: Colors.Grey
	},
	marginBottom12: {
		marginBottom: 12
	},
	marginVertical12: {
		marginVertical: 12
	},
	paymentInfoContainer: {
		backgroundColor: Colors.White,
		marginTop: 8,
		padding: 16
	}
});


const mapStateToProps = (state: ReduxState) => ({
	...state,
	checkoutState: state.order.checkout
});

const mapDispatchToProps = (dispatch: any) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentDetailBankScreen);
