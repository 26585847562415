import { PagingResponse } from './response/paging';
import { User } from './auth';
import { ProductKind, Product, ProductItem } from './product';
import { CheckoutResponse, PaymentMethodsResponse } from './order';
import BaseDefault from '../constants/state';

interface BaseState<T> {
	data: T | undefined;
	list: Array<T>;
	requesting: boolean;
	paging?: PagingResponse;
	error: string;
}

interface AuthState extends BaseState<User> {
	isLoggedIn: boolean;
}
interface ProductState {
	productKind: BaseState<ProductKind>;
	productItem: BaseState<ProductItem>;
	product: BaseState<Product>;
}
interface OrderState {
	checkout: BaseState<CheckoutResponse>;
	paymentMethods: BaseState<PaymentMethodsResponse>;
}

const ProductStateDefault: ProductState = {
	productKind: BaseDefault,
	productItem: BaseDefault,
	product: BaseDefault,
};

const OrderStateDefault: OrderState = {
	checkout: BaseDefault,
	paymentMethods: BaseDefault,
};

export {
	BaseState,
	AuthState,
	ProductState,
	OrderState,
	ProductStateDefault,
	OrderStateDefault,
};
