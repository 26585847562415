const Colors = {
	BlueOne: '#1D6FB9',
	BlueTwo: 'rgba(210, 240, 251, 0.5)',
	White: '#FFFFFF',
	Black: '#000000',
	Grey: '#B2B2B2',
	Grey1: '#333333',
	Grey2: '#E5E5E5',
	Grey3: '#F1F3F6',
	Grey4: '#F2F2F2',
	Grey5: '#D7D7D7',
	Grey6: '#656565',
	Orange: '#FF5518',
	Red: '#E95050',
	Green: '#0EAD31',
	Theme: '#051F58',
	Google: '#EA4335',
	Facebook: '#3A5799',
};

export default Colors;
