import { StyleSheet } from 'react-native';
import Colors from '../constants/color';

const button = StyleSheet.create({
	Base: {
		paddingVertical: 8,
		paddingHorizontal: 16,
		borderRadius: 4,
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
	},
});

const VarButton = StyleSheet.create({
	BlueVarOneButton: {
		...button.Base,
		backgroundColor: Colors.BlueOne,
		borderColor: Colors.BlueOne,
	},
	BlueVarTwoButton: {
		...button.Base,
		backgroundColor: Colors.BlueTwo,
		borderColor: Colors.BlueTwo,
	},
	Default: {
		...button.Base,
		backgroundColor: Colors.White,
		borderColor: Colors.Grey,
		borderWidth: 1,
	},
	Link: {
		...button.Base,
		paddingHorizontal: 0,
	},
	Large: {
		paddingVertical: 16,
		paddingHorizontal: 32,
	},
	divider: {
	},
});

export default VarButton;
