const SIGN_IN_WITH_EMAIL_REQUEST = 'SIGN_IN_WITH_EMAIL_REQUEST';
const SIGN_IN_WITH_EMAIL_SUCCESS = 'SIGN_IN_WITH_EMAIL_SUCCESS';
const SIGN_IN_WITH_EMAIL_FAILURE = 'SIGN_IN_WITH_EMAIL_FAILURE';

const SIGN_IN_WITH_FACEBOOK_REQUEST = 'SIGN_IN_WITH_FACEBOOK_REQUEST';
const SIGN_IN_WITH_FACEBOOK_SUCCESS = 'SIGN_IN_WITH_FACEBOOK_SUCCESS';
const SIGN_IN_WITH_FACEBOOK_FAILURE = 'SIGN_IN_WITH_FACEBOOK_FAILURE';

const SIGN_UP_REQUEST = 'SIGN_UP_REQUEST';
const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS';
const SIGN_UP_FAILURE = 'SIGN_UP_FAILURE';

const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';

const SIGN_OUT_SUCCESS = 'SIGN_OUT_SUCCESS';

export default {
	SIGN_IN_WITH_EMAIL_REQUEST,
	SIGN_IN_WITH_EMAIL_SUCCESS,
	SIGN_IN_WITH_EMAIL_FAILURE,

	SIGN_IN_WITH_FACEBOOK_REQUEST,
	SIGN_IN_WITH_FACEBOOK_SUCCESS,
	SIGN_IN_WITH_FACEBOOK_FAILURE,

	SIGN_UP_REQUEST,
	SIGN_UP_SUCCESS,
	SIGN_UP_FAILURE,

	UPDATE_USER_REQUEST,
	UPDATE_USER_SUCCESS,
	UPDATE_USER_FAILURE,

	SIGN_OUT_SUCCESS,
};
