const CHECKOUT_REQUEST = 'CHECKOUT_REQUEST';
const CHECKOUT_SUCCESS = 'CHECKOUT_SUCCESS';
const CHECKOUT_FAILURE = 'CHECKOUT_FAILURE';

const PAYMENT_METHODS_REQUEST = 'PAYMENT_METHODS_REQUEST';
const PAYMENT_METHODS_SUCCESS = 'PAYMENT_METHODS_SUCCESS';
const PAYMENT_METHODS_FAILURE = 'PAYMENT_METHODS_FAILURE';

const CLEAR_PAYMENT_METHOD_SUCCESS = 'CLEAR_PAYMENT_METHOD_SUCCESS';

export default {
	CHECKOUT_REQUEST,
	CHECKOUT_FAILURE,
	CHECKOUT_SUCCESS,

	PAYMENT_METHODS_FAILURE,
	PAYMENT_METHODS_REQUEST,
	PAYMENT_METHODS_SUCCESS,

	CLEAR_PAYMENT_METHOD_SUCCESS,
}
