import { action } from 'typesafe-actions';
import { Dispatch, AnyAction } from 'redux';
import Api from '../apis';
import Base from './base';
import { BaseResponse } from '../../model/response/base';
import { ProductKind, Product, ProductItem } from '../../model/product';
import ActionTypes from '../../constants/action-types';

const getAllProductsRequest = () =>
	action(ActionTypes.product.GET_ALL_PRODUCTS_REQUEST);
const getAllProductsFailure = (error: string) =>
	action(ActionTypes.product.GET_ALL_PRODUCTS_FAILURE, error);
const getAllProductsSuccess = (data: Array<ProductKind>) =>
	action(ActionTypes.product.GET_ALL_PRODUCTS_SUCCESS, data);

const getAllProducts = () => (
	dispatch: Dispatch<AnyAction>,
) => {
	Base.CallAction(
		dispatch,
		Api.Products.getAllProducts(),
		getAllProductsRequest,
		(response: BaseResponse<Array<ProductKind>>) => getAllProductsSuccess(response.data),
		getAllProductsFailure,
	);
};

const getProductRequest = () =>
	action(ActionTypes.product.GET_PRODUCT_REQUEST);
const getProductFailure = (error: string) =>
	action(ActionTypes.product.GET_PRODUCT_FAILURE, error);
const getProductSuccess = (data: Product) =>
	action(ActionTypes.product.GET_PRODUCT_SUCCESS, data);

const getProduct = (data: {productId: number}) => (
	dispatch: Dispatch<AnyAction>,
) => {
	Base.CallAction(
		dispatch,
		Api.Products.getProduct(data),
		getProductRequest,
		(response: BaseResponse<Product>) => getProductSuccess(response.data),
		getProductFailure,
	);
};

const getProductItemsRequest = () =>
	action(ActionTypes.product.GET_PRODUCT_ITEMS_REQUEST);
const getProductItemsFailure = (error: string) =>
	action(ActionTypes.product.GET_PRODUCT_ITEMS_FAILURE, error);
const getProductItemsSuccess = (data: Array<ProductItem>) =>
	action(ActionTypes.product.GET_PRODUCT_ITEMS_SUCCESS, data);

const getProductItems = (data: {productId: number}) => (
	dispatch: Dispatch<AnyAction>,
) => {
	Base.CallAction(
		dispatch,
		Api.Products.getProductItems(data),
		getProductItemsRequest,
		(response: BaseResponse<Array<ProductItem>>) => getProductItemsSuccess(response.data),
		getProductItemsFailure,
	);
};

const selectProductSuccess = (data: Product) => action(ActionTypes.product.SELECT_PRODUCT_SUCCESS, data);
const selectProduct = (data: Product) => (dispatch: Dispatch<AnyAction>) => {
	dispatch(selectProductSuccess(data));
}

export default {
	getAllProducts,
	getProduct,
	getProductItems,
	selectProduct,
};
