import { StyleSheet } from 'react-native';
import Colors from '../constants/color';

const MainLayout = StyleSheet.create({
	container: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
	},
	wrapper: {
		width: '100%',
		flex: 1,
		backgroundColor: 'transparent',
	},
	row: {
		flexDirection: 'row',
	},
	rowCentered: {
		flexDirection: 'row',
		justifyContent: 'center',
	},
	rowSpaced: {
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	column: {
		flexDirection: 'column',
	},
	columnCentered: {
		flexDirection: 'column',
		justifyContent: 'center',
	},
	fullFlex: {
		flex: 1,
	},
	errorBar: {
		backgroundColor: Colors.Red,
		paddingVertical: 8,
		paddingHorizontal: 32,
		borderRadius: 4,
	},
});

export default MainLayout;
