import { StyleSheet } from 'react-native';
import Colors from '../constants/color';

const form = StyleSheet.create({
	Base: {
		borderRadius: 4,
	},
});

const MainLayout = StyleSheet.create({
	normal: {
		...form.Base,
		borderColor: Colors.Grey,
	},
	error: {
		...form.Base,
		borderColor: Colors.Red,
	},
});

export default MainLayout;
