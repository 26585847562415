import { Dispatch, AnyAction } from 'redux';
import { ApiResponse } from '../../model/response/api';
import { BaseResponse } from '../../model/response/base';
import * as Snackbar from '../../components/snackbar';

const ErrorResponse = (error: any, dispatch: Dispatch<AnyAction>, onFailed: (message: string) => any ) => {
	if (error.response) {
		Snackbar.show(error.response.data.errors.description, Snackbar.MEDIUM, Snackbar.WARNING);
		dispatch(onFailed(error.response.data.errors.description));
	} else {
		// Something happened in setting up the request that triggered an Error
		Snackbar.show(error.message, Snackbar.MEDIUM, Snackbar.WARNING);
		dispatch(onFailed(error));
	}
}


const CallAction = (
	dispatch: Dispatch<AnyAction>,
	api: Promise<ApiResponse>,
	onRequested: () => any,
	onSuccess: (data: BaseResponse<any>) => any,
	onFailed: (message: string) => any,
) => {
	dispatch(onRequested());
	return api
		.then((response) => {
			if (response.data.success) {
				dispatch(onSuccess(response.data));
			}
		})
		.catch((error: any) => {
			ErrorResponse(error, dispatch, onFailed);
		});
};

export default {
	CallAction,
	ErrorResponse,
};
