import React, { useState } from 'react';
import { StyleSheet, TouchableOpacity, View, Dimensions } from 'react-native';
import { SafeAreaLayout } from '../components/ui-kitten-kit/safe-area-layout.component';
import {
	Text,
	Tab,
	TabView,
	Layout,
	Button,
	Input,
	Icon,
} from '@ui-kitten/components';
import Colors from '../constants/color';
import { PADDING } from '../constants';
import { connect } from 'react-redux';
import { ReduxState } from '../reduxers/reducers';
import Actions from '../reduxers/actions';
import { AuthParams } from '../model/auth';
import * as Snackbar from '../components/snackbar';

interface MainProps {
	navigation: any;
	signin: (
		data: AuthParams,
		navigation: Function,
		resetState: Function,
	) => Promise<any>;
	signup: (data: AuthParams) => Promise<any>;
}

const screenWidth = Dimensions.get('window').width;

const AuthScreen = (props: MainProps): React.ReactElement => {
	const { navigation, signin, signup } = props;

	const [selectedIndex, setSelectedIndex] = useState(0);
	const [email, setEmail] = useState({ value: '', isValid: true });
	const [password, setPassword] = useState({ value: '', isValid: true });
	const [confirmPass, setConfirmPass] = useState({ value: '', isValid: true });
	const [isSecureText1, setIsSecureText1] = useState(true);
	const [isSecureText2, setIsSecureText2] = useState(true);

	const onIconPress1 = () => {
		setIsSecureText1(!isSecureText1);
	};

	const onIconPress2 = () => {
		setIsSecureText2(!isSecureText2);
	};

	const renderIcon1 = (style) => (
		<Icon {...style} name={isSecureText1 ? 'eye-off' : 'eye'} />
	);

	const renderIcon2 = (style) => (
		<Icon {...style} name={isSecureText2 ? 'eye-off' : 'eye'} />
	);

	const resetState = () => {
		setEmail({ value: '', isValid: true });
		setPassword({ value: '', isValid: true });
		setConfirmPass({ value: '', isValid: true });
		setIsSecureText1(true);
		setIsSecureText2(true);
	};

	const onSelectTab = (index: number) => {
		setSelectedIndex(index);
		// reset default state
		resetState();
	};

	const validate = (type: string) => {
		let requiredMessage = '';
		let isEmpty = false;
		let isMatch = true;
		if (email.value.length === 0) {
			setEmail({ ...email, isValid: false });
			requiredMessage = requiredMessage + 'email, ';
			isEmpty = true;
		} else if (password.value.length === 0) {
			setPassword({ ...password, isValid: false });
			requiredMessage = requiredMessage + 'password, ';
			isEmpty = true;
		} else if (type === 'register' && confirmPass.value.length === 0) {
			setConfirmPass({ ...confirmPass, isValid: false });
			requiredMessage = requiredMessage + 'konfirmasi password,';
			isEmpty = true;
		}
		requiredMessage =
			requiredMessage.substring(0, requiredMessage.length - 1) +
			' tidak boleh kosong';

		if (type === 'register' && password.value !== confirmPass.value) {
			setPassword({ ...password, isValid: false });
			setConfirmPass({ ...confirmPass, isValid: false });
			isMatch = false;
		}
		if (isEmpty) {
			Snackbar.show(requiredMessage, Snackbar.MEDIUM, Snackbar.WARNING);
			return false;
		} else if (type === 'register' && !isMatch) {
			Snackbar.show(
				'Kedua password tidak sama',
				Snackbar.MEDIUM,
				Snackbar.WARNING,
			);
			return false;
		} else {
			return true;
		}
	};

	const onLogin = async () => {
		if (validate('login')) {
			await signin(
				{ email: email.value, password: password.value },
				() => navigation.navigate('profile'),
				resetState,
			);
		}
	};

	const onRegister = () => {
		if (validate('register')) {
			signup({ email: email.value, password: password.value });
		}
	};

	const renderSocialMediaLoginButton = () => (
		<View style={styles.buttonContainer}>
			<View style={styles.buttonContainer1}>
				<Button style={styles.button1} onPress={() => null} activeOpacity={0.2}>
					Facebook
				</Button>
			</View>
			<View style={styles.buttonContainer1}>
				<Button style={styles.button2} onPress={() => null} activeOpacity={0.2}>
					Facebook
				</Button>
			</View>
		</View>
	);

	const renderRegisterForm = () => (
		<View style={styles.formContainer}>
			<View style={styles.formItemContainer}>
				<Input
					placeholder='youremail@gmail.com'
					value={email.value}
					label='Email'
					caption='required'
					captionIcon={() => <Icon name='alert-circle-outline' />}
					status={email.isValid ? 'basic' : 'danger'}
					onChangeText={(nextValue) => setEmail({ ...email, value: nextValue })}
				/>
			</View>
			<View style={styles.formItemContainer}>
				<Input
					placeholder='*****'
					value={password.value}
					label='Password'
					caption='required'
					captionIcon={() => <Icon name='alert-circle-outline' />}
					status={password.isValid ? 'basic' : 'danger'}
					icon={renderIcon1}
					onIconPress={onIconPress1}
					secureTextEntry={isSecureText1}
					onChangeText={(nextValue) =>
						setPassword({ ...password, value: nextValue })
					}
				/>
			</View>
			<View style={styles.formItemContainer}>
				<Input
					placeholder='*****'
					value={confirmPass.value}
					label='Confirm Password'
					caption='required'
					captionIcon={() => <Icon name='alert-circle-outline' />}
					status={confirmPass.isValid ? 'basic' : 'danger'}
					icon={renderIcon2}
					onIconPress={onIconPress2}
					secureTextEntry={isSecureText2}
					onChangeText={(nextValue) =>
						setConfirmPass({ ...confirmPass, value: nextValue })
					}
				/>
			</View>
			<View style={styles.registerButtonContainer}>
				<Button style={styles.button3} onPress={onRegister} activeOpacity={0.2}>
					Register
				</Button>
			</View>
		</View>
	);

	const renderLoginForm = () => (
		<View style={styles.formContainer}>
			<View style={styles.formItemContainer}>
				<Input
					placeholder='Place your Text'
					value={email.value}
					label='Email'
					onChangeText={(nextValue) => setEmail({ ...email, value: nextValue })}
				/>
			</View>
			<View style={styles.formItemContainer}>
				<Input
					placeholder='*****'
					value={password.value}
					label='Password'
					caption='Minimal 8 karakter'
					captionIcon={() => <Icon name='alert-circle-outline' />}
					icon={renderIcon1}
					onIconPress={onIconPress1}
					secureTextEntry={isSecureText1}
					onChangeText={(nextValue) =>
						setPassword({ ...password, value: nextValue })
					}
				/>
			</View>
			<View style={styles.emptySpace} />
			<View style={styles.registerButtonContainer}>
				<Button style={styles.button3} onPress={onLogin} activeOpacity={0.2}>
					Login
				</Button>
			</View>
		</View>
	);

	const renderHeader = () => (
		<View>
			<TouchableOpacity
				onPress={() => navigation.navigate('home')}
				style={styles.rowCenter}
			>
				<Text style={styles.backText}>Halaman Utama</Text>
			</TouchableOpacity>
			<View style={styles.topTabBar}>
				<TabView
					selectedIndex={selectedIndex}
					onSelect={(index) => onSelectTab(index)}
				>
					<Tab title='REGISTER'>
						<Layout style={styles.tabContainer}>
							<View style={styles.infoContainer}>
								<Text style={styles.textCenter}>
									dapatkan DISKON 20% untuk transaksi pertama
								</Text>
							</View>
							{renderSocialMediaLoginButton()}
							{renderRegisterForm()}
						</Layout>
					</Tab>
					<Tab title='LOGIN'>
						<Layout style={styles.tabContainer}>
							<View style={styles.infoContainer}>
								<Text style={styles.textCenter}>
									Kumpulkan poin dan pakai poin untuk transaksi
								</Text>
							</View>
							{renderSocialMediaLoginButton()}
							{renderLoginForm()}
						</Layout>
					</Tab>
				</TabView>
			</View>
		</View>
	);
	return (
		<SafeAreaLayout style={styles.safeArea} insets='top'>
			{renderHeader()}
		</SafeAreaLayout>
	);
};

const styles = StyleSheet.create({
	safeArea: {
		flex: 1,
		backgroundColor: Colors.Grey2,
	},
	backText: {
		color: Colors.BlueOne,
	},
	rowCenter: {
		flex: 1,
		justifyContent: 'center',
		paddingHorizontal: 16,
		paddingVertical: 12,
	},
	topTabBar: {
		backgroundColor: Colors.White,
		paddingVertical: 12,
	},
	tabContainer: {
		alignItems: 'center',
		justifyContent: 'center',
	},
	infoContainer: {
		backgroundColor: Colors.BlueTwo,
		width: '100%',
		paddingVertical: 12,
	},
	textCenter: {
		textAlign: 'center',
	},
	button1: {
		flex: 1,
		backgroundColor: Colors.Facebook,
		marginRight: 8,
	},
	button2: {
		flex: 1,
		backgroundColor: Colors.Google,
		marginLeft: 8,
	},
	button3: {
		width: '60%',
		backgroundColor: Colors.BlueOne,
		marginLeft: 8,
	},
	buttonContainer: {
		flexDirection: 'row',
		width: screenWidth - PADDING * 2,
		paddingVertical: 12,
		marginBottom: 12,
	},
	registerButtonContainer: {
		alignItems: 'center',
		paddingVertical: 20,
	},
	buttonContainer1: {
		flex: 1,
	},
	formContainer: {
		width: screenWidth - PADDING * 2,
	},
	formItemContainer: {
		paddingVertical: 10,
	},
	emptySpace: {
		height: 100,
	},
});

const mapStateToProps = (state: ReduxState) => ({
	...state,
	productKinds: state.product.productKind.list,
});

const mapDispatchToProps = (dispatch: any) => ({
	signup: (data: AuthParams) => dispatch(Actions.auth.signUpWithEmail(data)),
	signin: (data: AuthParams, navigation: Function, resetState: Function) =>
		dispatch(Actions.auth.signInWithEmail(data, navigation, resetState)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthScreen);
