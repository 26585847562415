import { combineReducers } from 'redux';
import auth from './auth';
import product from './product';
import order from './order';
import { AuthState, ProductState, OrderState } from '../../model/state';

export interface ReduxState {
	auth: AuthState;
	product: ProductState;
	order: OrderState;
}

export const rootReducer = combineReducers<ReduxState>({
	auth,
	product,
	order
});
