import * as React from 'react';
import { View, Text, StyleSheet, TouchableWithoutFeedback } from 'react-native';
import { Icon } from '@ui-kitten/components';
import CommonStyle from '../../styles';
import Colors from '../../constants/color';
import { IFAQMeta } from '../../model/metadata';

interface MainProps {
	faq: IFAQMeta;
}

const styles = StyleSheet.create({
	wrapper: {
		borderColor: Colors.Grey,
		borderWidth: 1,
		borderRadius: 4,
		overflow: 'hidden',
	},
	body: {
		borderTopColor: Colors.Grey,
		borderTopWidth: 1,
	},
	icon: {
		width: 16,
		height: 16,
	},
	bodyCollapse: {
		height: 0,
	},
	answerContainer: {
		padding: 8
	},
	textLine: {
		lineHeight: 24
	},
	textTitle: {
		fontSize: 16,
		fontWeight: '600'
	}
});

const FAQCard = ({ faq }: MainProps) => {
	const [collapse, setCollapse] = React.useState(true);

	return (
		<View
			style={[CommonStyle.Space.MB1, CommonStyle.Layout.column, styles.wrapper]}
		>
			<TouchableWithoutFeedback onPress={() => setCollapse(!collapse)}>
				<View style={[CommonStyle.Layout.rowSpaced, CommonStyle.Space.M1]}>
					<Text
						style={[
							CommonStyle.Layout.fullFlex,
							CommonStyle.Space.MR1,
							styles.textTitle
						]}
					>
						{faq.q}
					</Text>
					<Icon
						style={styles.icon}
						fill={Colors.BlueOne}
						name={
							collapse
								? 'arrow-ios-downward-outline'
								: 'arrow-ios-upward-outline'
						}
					/>
				</View>
			</TouchableWithoutFeedback>
			<View
				style={[
					collapse ? CommonStyle.Space.PH0 : CommonStyle.Space.PH0_5,
					collapse ? CommonStyle.Space.MB0 : CommonStyle.Space.MB1,
					collapse ? styles.bodyCollapse : styles.body,
				]}
			>
				<View style={styles.answerContainer}>
					{faq.a.map(item => (
						<Text style={styles.textLine}>{item}</Text>
					))}
				</View>
			</View>
		</View>
	);
};

export default FAQCard;
