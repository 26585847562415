import axios from 'axios';
import ENV from '../../utils/environment';
import Products from './products';
import Auth from './auth';
import Order from './order';
import { ApiResponse } from '../../model/response/api';

axios.defaults.baseURL = ENV.API_URL;

export function setTokenRequestHeader(accessToken: string) {
  axios.defaults.headers.common = {'Authorization': `Bearer ${accessToken}`}
}

export function removeTokenRequestHeader() {
  axios.defaults.headers.common = '';
}

export default {
	Products,
	Auth,
	Order,
}
