/* eslint-disable no-unused-expressions */
import * as React from 'react';

export const navigationRef: React.RefObject<any> = React.createRef();

export function navigate(
	name: string,
	params: { [k: string]: string } | null = null,
) {
	navigationRef.current?.navigate(name, params);
}

export function current() {
	if (navigationRef && navigationRef.current) {
		const currentRoute = navigationRef.current.getCurrentRoute();
		return currentRoute ? currentRoute.name : '';
	}
	return '';
}

export function init(isLoggedIn: boolean) {
	const memberOnlyRoute = ['profile'];
	const route = current();
	if (memberOnlyRoute.includes(route)) {
		if (!isLoggedIn) { navigate('auth'); }
	}
}
