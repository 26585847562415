import * as React from 'react';
import Colors from '../../constants/color';
import SnackbarComponent from './snackbar';

export const SHORT = 1000;
export const MEDIUM = 3000;
export const LONG = 5000;

export const WARNING = [Colors.Google, Colors.White];
export const DEFAULT = [Colors.Grey, Colors.Black];
export const INFO = [Colors.BlueOne, Colors.White];

export const snackbarRef: React.RefObject<any> = React.createRef();

export function show(title: string, duration: number, mode?: Array<string>) {
	if (snackbarRef && snackbarRef.current) {
		snackbarRef.current.show(title, duration, mode);
	}
}

export default SnackbarComponent;
